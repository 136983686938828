import ic_calendar from './ic_calendar.svg';
import ic_call from './ic_call.svg';
import ic_lock from './ic_lock.svg';
import ic_operators from './ic_operators.svg';
import ic_settings from './ic_settings.svg';
import ic_stats_client from './ic_stats_client.svg';
import ic_stat_client_response from './ic_stat_client_response.svg';
import ic_analytics from './ic_analytics.svg';
import ic_todolist from './ic_todolist.svg';
import ic_offices from './ic_offices.svg';
import ic_notes from './ic_notes.svg';
import ic_finance from './ic_finance.svg';
import ic_file from './ic_fileShow.svg';
import ic_watch from './ic_watch.svg';
import ic_configuration from './ic_configuration.svg';
export { ic_calendar, ic_lock, ic_operators, ic_settings, ic_call, ic_stats_client, ic_stat_client_response, ic_todolist, ic_analytics, ic_offices, ic_notes, ic_finance, ic_file, ic_watch, ic_configuration, };
